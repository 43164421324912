<template>
	<div class="login">
		<!-- 登录 -->
		<el-form v-if="mode == 'login'" ref="login" :model="form1">
			<div class="title">{{ $t("login.title_sub") }}</div>
			<el-row>
				<el-form-item :label="$t('rigister.email')">
					<el-input v-model="form1.email" :placeholder="$t('rigister.emailTip')"></el-input>
				</el-form-item>
			</el-row>
			<el-row>
				<el-form-item :label="$t('rigister.password')">
					<el-input v-model="form1.password" type="password" :placeholder="$t('rigister.passwordTip')">
					</el-input>
				</el-form-item>
			</el-row>
			<!-- <el-row :gutter="10">
				<el-col :span="16">
					<el-form-item label="图形验证码">
						<el-input placeholder="请输入图中所显示的验证码"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="empty" class="empty">
						<button class="picCode" >
							<PicCode :identifyCode="code" ></PicCode>
						</button>
					</el-form-item>
				</el-col>
			</el-row> -->
			<div class="forget" @click="mode = 'forget'">{{ $t("login.forget") }}</div>
			<div class="button" @click="login" ref="login">
				<template v-if="subIng"><i class="el-icon-loading"></i></template>
				<template v-else>{{ $t("login.title") }}</template>
			</div>
			<div class="devide"></div>
			<div class="title">{{ $t("login.new1") }}</div>
			<div class="tips">
				<div class="item">{{ $t("login.new2") }}</div>
				<div class="item">{{ $t("login.able1") }}</div>
				<div class="item">{{ $t("login.able2") }}</div>
				<div class="item">{{ $t("login.able3") }}</div>
				<div class="item">{{ $t("login.able4") }}</div>
				<div class="item">{{ $t("login.able5") }}</div>
			</div>
			<div class="button noBack" @click="mode = 'regist'">{{ $t("rigister.title") }}</div>
		</el-form>
		<!-- 找回密码 -->
		<el-form v-if="mode == 'forget'" ref="forget_form" :model="form3">
			<div class="title">Recuperar contraseña</div>
			<el-row>
				<el-form-item :label="$t('rigister.email')">
					<el-input v-model="form3.email" :placeholder="$t('rigister.emailTip')"></el-input>
				</el-form-item>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="14">
					<el-form-item label="Código de verificación">
						<el-input v-model="form3.code" placeholder="Introduzca el Código de verificación"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="10">
					<el-form-item label="empty" class="empty">
						<button class="picCode" @click="sendEmailCode">
							Obtener Código de verificación{{code_time==0?'':"("+code_time+")"}}
						</button>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-form-item :label="$t('rigister.password')">
					<el-input v-model="form3.password" type="password" :placeholder="$t('rigister.passwordTip')">
					</el-input>
				</el-form-item>
			</el-row>
			<el-row>
				<el-form-item :label="$t('rigister.con_password')">
					<el-input v-model="form3.rePassword" type="password" :placeholder="$t('rigister.repasswordTip')">
					</el-input>
				</el-form-item>
			</el-row>
			<div class="button" @click="forget_form" style="margin-top: 40px">
				<template v-if="subIng"><i class="el-icon-loading"></i></template>
				<template v-else>Forgot Password</template>
			</div>
			<div class="switch">
				{{ $t("login.title_sub") }}?<span @click="mode = 'login'">{{
		  $t("login.title")
		}}</span>
			</div>
		</el-form>
		<!-- 注册 -->
		<el-form v-if="mode == 'regist'" ref="form" :model="form2">
			<div class="title">{{ $t("rigister.confrim") }}</div>
			<el-row>
				<el-form-item :label="$t('rigister.email')">
					<el-input v-model="form2.email" :placeholder="$t('rigister.emailTip')"></el-input>
				</el-form-item>
			</el-row>
			<el-row>
				<el-form-item :label="$t('rigister.password')">
					<el-input v-model="form2.password" type="password" :placeholder="$t('rigister.passwordTip')">
					</el-input>
				</el-form-item>
			</el-row>
			<el-row>
				<el-form-item :label="$t('rigister.con_password')">
					<el-input v-model="form2.rePassword" type="password" :placeholder="$t('rigister.repasswordTip')">
					</el-input>
				</el-form-item>
			</el-row>
			<div class="button" @click="regist" style="margin-top: 40px">
				<template v-if="subIng"><i class="el-icon-loading"></i></template>
				<template v-else>{{ $t("rigister.title") }}</template>
			</div>
			<div class="switch">
				{{ $t("login.title_sub") }}?<span @click="mode = 'login'">{{
          $t("login.title")
        }}</span>
			</div>
		</el-form>
	</div>
</template>

<script>
	import PicCode from "../code/picCode.vue";
	import yCode from "../code/yCode.vue";
	import Api from "../../api/request.js";
	import {
		mapMutations,
		mapState
	} from 'vuex';
	export default {
		name: "Login",
		components: {
			PicCode,
			yCode,
		},
		data() {
			return {
				subIng: false,
				dontAgain: false,
				mode: "login",
				code: "8997",
				form1: {
					email: "",
					password: "",
				},
				form2: {
					email: "",
					password: "",
					rePassword: "",
				},
				form3: {
					email: "",
					code: "",
					password: "",
					rePassword: "",
				},
				code_time: 0
			};
		},
		computed: {
			...mapState(['cartTemp']),
		},
		created() {
			document.onkeyup = () => {
				var key = window.event.keyCode;
				if (key == 13) {
					if (this.$refs.login) {
						this.login();
					}
				}
			};
		},
		methods: {
			...mapMutations(['setCartTemp']),
			syncCarts(callback) {
				let that = this;
				if (that.$store.state.isLogin && that.cartTemp && that.cartTemp.length > 0) {
					let addcount = 0;
					that.cartTemp.map((item) => {
						Api.Cart.add(item)
							.then(res => {
								addcount++;
								if (res.data.status == 'SUCCESSS') {
									if (addcount == that.cartTemp.length) {
										that.setCartTemp([]);
										that.$bus.$emit("getCartNum");
									}
								} else {
									if (callback) {
										callback();
									}
								}
							});
					})
				} else {
					if (callback) {
						callback();
					}
				}
			},
			login() {
				if (this.subIng) {
					this.$dialog.notify(this.$t("tip.title"), this.$t("tip.repeat"));
				} else {
					this.subIng = true;
					Api.Login.login(this.form1).then((res) => {
						if (res.data.status == "SUCCESSS") {
							this.$dialog.notify(this.$t("tip.loginSuc"), this.$t("tip.loginSuc_dec"));
							this.$storage.set("user_info", res.data.data);
							this.$store.state.isLogin = true;
							this.$parent.$parent._data.show.login = false;

							//同步离线购物车
							this.syncCarts(() => {});

						}
						this.subIng = false;
					});
				}
			},
			jsq_send_code() {
				this.send_code_time = setTimeout(() => {
					if (this.code_time > 0) {
						this.code_time--;
						this.jsq_send_code();
					} else {
						this.code_time = 0;
					}
				}, 1000);
			},
			sendEmailCode() {
				if (this.subIng) {
					this.$dialog.notify(this.$t("tip.ing"), this.$t("tip.repeat"));
				} else if (this.code_time > 0) {
					this.$dialog.notify(this.$t("tip.ing"), this.$t("tip.repeat"));
				} else {
					this.subIng = true;
					Api.Login.getEmailCode({
							email: this.form3.email
						})
						.then((res) => {
							if (res.data.status == "SUCCESSS") {
								this.$dialog.notify(this.$t("tip.title"), this.$t("tip.emailErr"));
								this.code_time = 60;
								this.jsq_send_code();
							}
							this.subIng = false;
						})
						.catch((res) => {
							this.subIng = false;
							this.code_time = 0;
							this.$dialog.notify(this.$t("tip.title"), this.$t("tip.emailErr"));
						});
				}
			},
			//找回密码
			forget_form() {
				if (this.subIng) {
					this.$dialog.notify(this.$t("tip.ing"), this.$t("tip.repeat"));
				} else {
					this.subIng = true;
					Api.Login.resetpwd(this.form3)
						.then((res) => {
							if (res.data.status == "SUCCESSS") {
								this.$dialog.notify("Contraseña recuperada con éxito", "Forgot Password");
								this.form1 = {
									email: this.form3.email,
									password: this.form3.password,
								};
								this.mode = "login";
							}
							this.subIng = false;
						})
						.catch((res) => {
							this.subIng = false;
							this.$dialog.notify(this.$t("tip.title"), this.$t("tip.emailErr"));
						});
				}
			},
			regist() {
				if (this.subIng) {
					this.$dialog.notify(this.$t("tip.ing"), this.$t("tip.repeat"));
				} else if (this.dontAgain) {
					this.$dialog.notify(this.$t("tip.onemin"), this.$t("tip.emailRepeat"));
				} else {
					this.dontAgain = true;
					setTimeout((res) => {
						this.dontAgain = false;
					}, 60000);
					this.subIng = true;
					Api.Login.regist(this.form2)
						.then((res) => {
							if (res.data.status == "SUCCESSS") {
								this.$confirm(this.$t("login.created"), this.$t("login.title_sub"), {
									showCancelButton: false,
									confirmButtonText: this.$t("login.created"),
									confirmButtonClass: "confirm_btn",
								}).then((res) => {
									this.form1 = {
										email: this.form2.email,
										password: this.form2.password,
									};
									this.mode = "login";
								});
							}
							this.subIng = false;
						})
						.catch((res) => {
							this.subIng = false;
							this.$dialog.notify(this.$t("tip.title"), this.$t("tip.emailErr"));
						});
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.login {
		height: calc(100vh - 60px);
		overflow: auto;
	}

	.Protocol {
		margin: 20px 0;
		display: flex;
		align-items: center;

		&>span {
			font-size: 14px;
		}

		.check {
			width: 12px;
			height: 12px;
			background: #ffffff;
			border: 1px solid #757575;
			margin-right: 10px;
			cursor: pointer;
		}
	}

	.switch {
		font-size: 14px;
		margin-top: 20px;

		&>span {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.el-form {
		padding: 0 100px;

		.forget {
			font-size: 14px;
			color: #999999;
			line-height: 20px;
			margin: 12px 0 20px;
			text-decoration: underline;
			cursor: pointer;
		}

		.button {
			height: 38px;
			line-height: 38px;
			background: #123178;
			border-radius: 6px;
			border: 1px solid #123178;
			text-align: center;
			color: #fff;
			cursor: pointer;

			&.noBack {
				background: #fff;
				border: 1px solid #123178;
				text-align: center;
				color: #123178;

				&:hover {
					background-color: #efefef;
				}
			}

			&:hover {
				background-color: #021450;
			}
		}

		.devide {
			height: 1px;
			background: #d9d9d9;
			margin: 40px 0;
		}

		.tips {
			overflow: hidden;
			margin-bottom: 20px;

			.item {
				font-size: 14px;
				color: #000000;
				line-height: 20px;
				margin-bottom: 20px;
				display: flex;
				align-items: center;

				&:nth-child(n + 2)::before {
					content: "";
					display: block;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background: #000000;
					margin-right: 0.5em;
				}
			}
		}

		.title {
			font-size: 18px;
			font-weight: bold;
			color: #000000;
			line-height: 25px;
			text-align: center;
			margin-bottom: 40px;
		}

		.el-row {
			margin-top: 20px;

			&:first-child {
				margin-top: 0;
			}
		}

		/deep/.el-form-item {
			margin-bottom: 0;

			&.empty {
				.el-form-item__label {
					opacity: 0;
				}
			}

			.picCode {
				display: block;
				width: 100%;
				height: 40px;
				background: #ccc;
				border: none;
			}
		}

		/deep/.el-form-item__label {
			font-size: 14px;
			font-weight: 400;
			color: #000000;
			line-height: 20px;
			margin: 0 0 12px;
		}

		/deep/.el-input__inner {
			border-radius: 0;
			border-color: #757575;
		}

		/deep/.el-textarea__inner {
			border-radius: 0;
			border-color: #757575;
			height: 208px;
		}

		/deep/.el-select .el-input .el-select__caret {
			color: #000;
			font-size: 12px;
			font-weight: bolder;
		}
	}

	@media screen and (max-width: 650px) {
		.el-form {
			padding: 0 40px;
		}
	}
</style>
