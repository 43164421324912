<template>
	<div class="yCode">
		<span @click="send" >发送验证码</span>
	</div>
</template>

<script>
import country from '../../assets/js/country.js'
import Api from '../../api/request.js'
export default {
	name: 'yCode',
	components:{
	},
	data() {
		return {
		};
	},
	created() {
	},
	methods:{
		send(){
			Api.Login.sendCode({phone: 18408250945,countryCode: '86'})
		}
	}
};
</script>

<style lang="less" scoped>
.yCode{
	height: calc(100% - 2px);
	line-height: 38px;
	width: calc(100% - 1px);
	border: 1px solid #000;
	border-left: none;
	background: #fff;
	font-size: 14px;
	cursor: pointer;
}
</style>
