<template>
	<div class="header">
		<div class="main">
			<div class="left">
				<i class="iconfont icon-menue" @click="show.menue = !show.menue"></i>
				<img @click="$router.replace('/Home')" src="../../assets/icon/logo.png" />
			</div>
			<div class="right">
				<!-- 收藏 -->
				<img @click="$store.state.isLogin ? $router.push('/Collect') : (show.login = true)"
					src="../../assets/icon/like.png" />
				<!--购物车-->
				<img @click="$store.state.isLogin ? $router.push('/Cart') : (show.login = true)"
					src="../../assets/icon/basket.png" />
				<!--我的-->
				<img src="../../assets/icon/personal.png"
					@click="$store.state.isLogin ? (show.mine = true) : (show.login = true)" />
			</div>
		</div>
		<div v-if="show.menue" class="slideDown">
			<div class="search">
				<i class="el-icon-search"></i>
				<input confirm-type="send" type="search" :placeholder="$t('nav.searchHolder')" />
			</div>
			<div class="mob_line"></div>
			<!-- link -->
			<div class="links">
				<!-- <div
          class="item"
          @click="$router.push(`/GoodsList?cateId=${val.categoryId}`)"
          v-for="(val, key) in guide"
          :key="key"
        >
          {{ val.categoryName }}
          <template v-for="(v, vkey) in val.sons">
            <div :key="vkey" class="category">
              <span @click.stop="$router.push(`/GoodsList?cateId=${v.categoryId}`)">
                {{ v.categoryName }}
              </span>
              <span
                v-for="(item, key) in v.sons"
                :key="key"
                @click.stop="$router.push(`/GoodsList?cateId=${item.categoryId}`)"
              >
                {{ item.categoryName }}
              </span>
            </div>
          </template>
        </div> -->
				<el-collapse v-model="firstName" accordion>
					<template v-for="(item, index) in guide">
						<template v-if="item.sons == null || item.sons.length==0">
							<p style="padding: 5px 0px;"
								@click.stop="$router.push(`/GoodsList?cateId=${item.categoryId}`)">
								{{ item.categoryName }}
							</p>
						</template>
						<template v-else>
							<el-collapse-item style="padding: 5px 0px;" :key="index" :title="item.categoryName"
								:name="index">
								<el-collapse v-model="secondName" class="second" accordion>
									<template
										v-if="item.sons && item.sons.length>0 && (item.sons[0].sons ==null || item.sons[0].sons.length ==0)">
										<p class="child" style="padding: 5px 0px;" v-for="(v, i) in item.sons" :key="i"
											@click.stop="$router.push(`/GoodsList?cateId=${v.categoryId}`)">
											{{ v.categoryName }}
										</p>
									</template>
									<template v-else>
										<el-collapse-item style="padding: 5px 0px;" v-for="(val, num) in item.sons"
											:key="num" :title="val.categoryName" :name="num">
											<p class="child" v-for="(v, i) in val.sons" :key="i"
												@click.stop="$router.push(`/GoodsList?cateId=${v.categoryId}`)">
												{{ v.categoryName }}
											</p>
										</el-collapse-item>
									</template>
								</el-collapse>
							</el-collapse-item>
						</template>
					</template>


				</el-collapse>
			</div>
			<div class="mob_line"></div>
			<div class="links">
				<div class="item" @click="$store.state.isLogin ? $router.push('/Collect') : (show.login = true)">
					{{ $t("userTab.wish") }}
					<i class="el-icon-arrow-right"></i>
				</div>
				<div class="item" @click="$store.state.isLogin ? $router.push('/Cart') : (show.login = true)">
					{{ $t("nav.shopping_cart") }}
					<i class="el-icon-arrow-right"></i>
				</div>
				<!--
				<div class="item langBox" @click="handlePickLang">
					{{ $store.state.language == "es-ES" ? "Español" : "English" }}
					<i class="el-icon-arrow-right"></i>
					<div ref="selectLang" class="selectLang hide">
						<p :class="$store.state.language == 'es-ES' ? 'currentLang' : ''"
							@click.stop="setLang('es-ES')">
							Español
						</p>
						<p :class="$store.state.language == 'en-GB' ? 'currentLang' : ''"
							@click.stop="setLang('en-GB')">
							English
						</p>
						<p :class="$store.state.language == 'zh-CN' ? 'currentLang' : ''"
							@click.stop="setLang('zh-CN')">
							Chinese
						</p>
					</div>
				</div>-->
			</div>
		</div>
		<!-- "我的"抽屉 -->
		<el-drawer class="r2l" append-to-body size="70vw" :visible.sync="show.mine" direction="rtl">
			<div class="mineLink">
				<div class="item" @click="
            $router.push('/OrderList');
            show.mine = false;
          ">
					<span>{{ $t("userTab.order") }}</span>
					<i class="el-icon-arrow-right"></i>
				</div>
				<div class="item" @click="
            $router.push('/Information');
            show.mine = false;
          ">
					<span>{{ $t("userTab.setting") }}</span>
					<i class="el-icon-arrow-right"></i>
				</div>
				<div class="item" @click="
            $router.push('/Address');
            show.mine = false;
          ">
					<span>{{ $t("userTab.address") }}</span>
					<i class="el-icon-arrow-right"></i>
				</div>
			</div>
			<div class="quit" @click="$bus.$emit('quit')">{{ $t("login.logoutTitle") }}</div>
		</el-drawer>
		<!-- 登录注册抽屉 -->
		<el-drawer class="r2l" append-to-body size="100vw" :visible.sync="show.login" direction="rtl">
			<Login v-if="show.login"></Login>
		</el-drawer>
	</div>
</template>

<script>
	import Api from "../../api/request.js";
	import Login from "./login.vue";
	export default {
		name: "Header",
		components: {
			Login,
		},
		watch: {
			$route(res) {
				this.show.search = false;
				this.show.mine = false;
				this.show.login = false;
				this.show.menue = false;
				if (res.path == "/GoodsList") {
					this.current = res.query.cateId;
				} else {
					this.current = "";
				}
			},
		},
		data() {
			return {
				show: {
					search: false,
					mine: false,
					login: false,
					menue: false,
				},
				search: {
					sex: 1,
					value: "",
				},
				guide: [],
				current: "",
				firstName: 0,
				secondName: 0,
			};
		},
		created() {
			this.$bus.$on("login", (res) => {
				this.show.login = true;
			});
			this.getCates();
		},
		methods: {
			getCates() {
				this.guide = this.$store.state.cateList;
			},
			goSearch() {
				this.show.search = false;
				this.$router.push(`/Search?key=${this.search.value}`);
			},
			handlePickLang() {
				this.$refs.selectLang.classList.toggle("hide");
			},
			setLang(res) {
				this.$i18n.locale = res;
				this.$store.state.language = res;
				this.$refs.selectLang.classList.toggle("hide");
			},
		},
	};
</script>

<style lang="less" scoped>
	.header {
		height: 55px;
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: 99;

		.slideDown {
			position: fixed;
			background: #123178;
			padding: 0 20px;
			left: 0;
			right: 0;
			top: 55px;
			bottom: 0;
			overflow: scroll;

			.links {
				color: #fff;
				font-size: 3.3vw;
				margin: 3vw 0;

				.item {
					padding: 2vw 0;

					.category {
						margin-top: 10px;

						>span {
							display: inline-block;
							margin: 0 15px 10px 0;
							line-height: 1;
						}
					}

					&.langBox {
						position: relative;

						.selectLang {
							background-color: #fff;
							border-radius: 5px;
							position: absolute;
							top: 15px;
							left: 65px;
							color: #333;
							padding: 10px 0;

							&.hide {
								visibility: hidden;
							}

							>p {
								height: 20px;
								line-height: 20px;
								padding: 0 20px;
							}

							p+p {
								margin-top: 10px;
							}
						}
					}
				}
			}

			.search {
				height: 35px;
				background: #fff;
				display: flex;
				align-items: center;
				margin: 15px 0;

				i {
					font-size: 5vw;
					margin: 0 2vw;
				}

				&>input {
					flex: 1;
					font-size: 3.3vw;
				}
			}
		}

		.main {
			height: 100%;
			display: flex;
			background: #fff;
			align-items: center;
			justify-content: space-between;

			.left {
				display: flex;
				align-items: center;
				justify-content: space-between;

				&>img {
					height: 23px;
				}

				&>i {
					font-size: 20px;
					margin: 0 18px;
					font-style: normal;
				}
			}

			.right {
				&>img {
					height: 20px;
					margin-right: 18px;
				}
			}
		}
	}

	.mineLink {
		padding: 0 3vw;

		.item {
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&>span {
				font-size: 14px;
			}

			&>i {
				font-size: 13px;
				font-weight: bolder;
			}

			border-bottom: 1px solid #d9d9d9;
			cursor: pointer;
		}
	}

	.r2l {
		.el-drawer__body {
			position: relative;

			.quit {
				position: absolute;
				bottom: 20px;
				left: 40px;
				right: 40px;
				height: 40px;
				line-height: 41px;
				background: #ffffff;
				border-radius: 6px;
				border: 1px solid #123178;
				text-align: center;
				font-size: 3.5vw;
				font-weight: bold;
				color: #123178;
				cursor: pointer;

				&:hover {
					background-color: #efefef;
				}
			}
		}
	}

	.currentLang {
		font-weight: bold;
	}

	.el-collapse {
		border: none;

		/deep/.el-collapse-item__header {
			background-color: #123178;
			color: #fff;
			border-bottom: none;
			line-height: 25px;
			height: 25px;
			font-size: 3.3vw;
		}

		/deep/.el-collapse-item__wrap {
			background-color: #123178;
			border-bottom: none;
		}

		/deep/.el-collapse-item__content {
			padding-bottom: 15px;
		}
	}

	.second {
		padding-left: 10px;
	}

	.child {
		padding-left: 10px;
		color: #fff;
		font-size: 3.3vw;
		line-height: 25px;
		height: 25px;
	}
</style>
