<template>
	<div>
		<el-breadcrumb v-if="$route.path == '/GoodsDetail'" class="bread" separator="|">
			<template v-for="(val, key) in history">
				
				<el-breadcrumb-item v-if="key != history.length - 1" :to="{ path: val.fullPath }">{{ val.title }}
				</el-breadcrumb-item>
				<el-breadcrumb-item v-else>{{ val.title }}</el-breadcrumb-item>
			</template>
		</el-breadcrumb>
	</div>
</template>
<script>
	export default {
		name: "BreadCrumb",
		data() {
			return {
				history: [{
					title:
						!this.$store.state.language || this.$store.state.language == "es-ES" ?
						"Inicio" : this.$store.state.language == "en-GB" ?
						"Home" : "首页",
					path: "/Home",
					fullPath: "/Home",
				}, ],
			};
		},
		watch: {
			$route(res) {
				let isBack = this.$router.isBack; // 监听路由变化时的状态为前进还是后退
				if (isBack) {
					if (this.history.length > 1) {
						var index = this.history.findIndex((item) => {
							return item.path == res.path;
						});
						this.history.splice(index, this.history.length - 1 - index);
					}
				} else {
					var isHere = this.history.findIndex((item) => {
						return item.path == res.path;
					});
					if (
						this.history.findIndex((item) => {
							return item.path == res.path;
						}) == -1
					) {
						this.history.push({
							title: res.meta.title,
							path: res.path,
							fullPath: res.fullPath,
						});
					}
				}
				this.history = this.history.filter(item => {
					return ["/Home","/GoodsList","/GoodsDetail"].indexOf(item.path) != -1;
				})
				console.log(this.history);
			},
		},
		created() {},
		methods: {},
	};
</script>
<style lang="less" scoped>
	.bread {
		height: 60px !important;
		display: flex;
		align-items: center;
		padding: 0 46px;
	}
</style>
